import React, { Component } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { ensureCurrentUser, userAbbreviatedName } from '../../../util/data';
import { isUploadImageOverLimitError } from '../../../util/errors';
import {
  Form,
  Avatar,
  IconSpinner,
  FieldSelect,
  PrimaryButton,
  ImageFromFile,
  FieldTextInput,
  FieldMultiSelectCustomField,
} from '../../../components';

import css from './Step1CompanyForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset


class Step1CompanyFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return <FinalForm
      {...this.props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          form,
          config,
          values,
          pristine,
          displayName,
          currentUser,
          profileImage,
          configListing,
          onImageUpload,
          uploadInProgress,
          uploadImageError,
          initialValues
        } = fieldRenderProps;
        const {companyIndustry=[]} = values ||{}

        const user = ensureCurrentUser(currentUser);

        const companyRequired = validators.requiredStringNoTrim(intl.formatMessage({
          id: 'SignupForm.companyRequired',
        }));
        const uploadingOverlay =
          uploadInProgress || this.state.uploadDelay ? (
            <div className={css.uploadingImageOverlay}>
              <IconSpinner />
            </div>
          ) : null;

        const hasUploadError = !!uploadImageError && !uploadInProgress;
        const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
        const transientUserProfileImage = profileImage && profileImage.uploadedImage || user.profileImage;
        const transientUser = { ...user, profileImage: transientUserProfileImage };

        // Ensure that file exists if imageFromFile is used
        const fileExists = !!(profileImage && profileImage.file);
        const fileUploadInProgress = uploadInProgress && fileExists;
        const delayAfterUpload = profileImage && profileImage.imageId && this.state.uploadDelay;
        const imageFromFile =
          fileExists && (fileUploadInProgress || delayAfterUpload) ? (
            <ImageFromFile
              id={profileImage.id}
              className={errorClasses}
              rootClassName={css.uploadingImage}
              aspectWidth={1}
              aspectHeight={1}
              file={profileImage.file}
            >
              {uploadingOverlay}
            </ImageFromFile>
          ) : null;

        // Avatar is rendered in hidden during the upload delay
        // Upload delay smoothes image change process:
        // responsive img has time to load srcset stuff before it is shown to user.
        const avatarClasses = classNames(errorClasses, css.avatar, {
          [css.avatarInvisible]: this.state.uploadDelay,
        });
        const avatarComponent =
          !fileUploadInProgress && profileImage && profileImage.imageId ? (
            <Avatar
              className={avatarClasses}
              renderSizes="(max-width: 767px) 96px, 240px"
              user={transientUser}
              disableProfileLink
            />
          ) : null;
        const abbreviatedName = userAbbreviatedName(transientUser, '');
        const chooseAvatarLabel =
          (profileImage && profileImage.imageId) || fileUploadInProgress ? (
            <div className={css.avatarContainer}>
              {imageFromFile}
              {avatarComponent}
              <div className={css.changeAvatar}>
                <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
              </div>
            </div>
          ) : <div className={css.logoAbbreviated}>{abbreviatedName}</div>;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submittedOnce = Object.keys(this.submittedValues).length > 0;
        const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
        const submitDisabled =
          invalid  || inProgress || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.signupNext}>
              <h3><FormattedMessage id='Step1CompanyForm.heading' values={{ displayName }} /></h3>

              <FieldTextInput
                className={css.inputBox}
                type="text"
                id={formId ? `${formId}.companyName` : 'companyName'}
                name="companyName"
                label={intl.formatMessage({
                  id: 'SignupForm.companyNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.companyNamePlaceholder',
                })}
                validate={companyRequired}
              />
              <FieldSelect
                id={`${formId}.sizeOfCompany`}
                className={css.inputBox}
                name="sizeOfCompany"
                label={intl.formatMessage({ id: 'Step1CompanyForm.sizeOfCompany' })}
                validate={validators.required(intl.formatMessage({ id: 'Step1CompanyForm.sizeOfCompanyodRequired' }))}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'Step1CompanyForm.selectSizeOfCompany' })}
                </option>

                {configListing.companySize.map(({ option, label }) => (
                  <option key={option} value={option}>
                    {label}
                  </option>
                ))}
              </FieldSelect>

              <FieldSelect
                id={`${formId}.foundingYear`}
                className={css.inputBox}
                name="foundingYear"
                label={intl.formatMessage({ id: 'Step1CompanyForm.foundingYear' })}
                validate={validators.required(intl.formatMessage({ id: 'Step1CompanyForm.foundingYearhodRequired' }))}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'Step1CompanyForm.selectFoundingYear' })}
                </option>
                {Array(moment().diff(moment('1949', 'YYYY'), 'years')).fill(',').map((k, i) => (
                  <option
                    key={moment().subtract(i, 'year').format('YYYY')}
                    value={moment().subtract(i, 'year').format('YYYY')}
                  >
                    {moment().subtract(i, 'year').format('YYYY')}
                  </option>
                ))}
              </FieldSelect>

              <FieldMultiSelectCustomField
                id={`${formId}.companyIndustry`}
                className={css.inputBox}
                name="companyIndustry"
                label={intl.formatMessage({ id: 'Step1CompanyForm.companyIndustry' })}
                validate={validators.required(intl.formatMessage({ id: 'Step1CompanyForm.companyIndustryRequired' }))}
                data={configListing.companyIndustry}
                placeholder={intl.formatMessage({
                  id: 'Step1CompanyForm.companyIndustry',
                })}
                onChange={selected => form.change('companyIndustry', selected)}
                initialval={companyIndustry}
              />

              {/* <FieldSelect
                id={`${formId}.companyIndustry`}
                className={css.inputBox}
                name="companyIndustry"
                label={intl.formatMessage({ id: 'Step1CompanyForm.companyIndustry' })}
                validate={validators.required(intl.formatMessage({ id: 'Step1CompanyForm.companyIndustryRequired' }))}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'Step1CompanyForm.selectCompanyIndustry' })}
                </option>

                {configListing.companyIndustry
                  ? configListing.companyIndustry.map(({ option, label }) => (
                    <option key={option} value={option}>
                      {label}
                    </option>
                  ))
                  : null}
              </FieldSelect> */}

              <div className={css.sectionContainer}>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                      const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
                      const formData = new FormData();
                      const fileType = file.type;
                      formData.append('file', file);
                      formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
                      formData.append('resource_type', fileType);
                      formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
                      fetch(uploadURL, { method: 'POST', body: formData })
                        .then(response => response.text())
                        .then(data => {
                          const res = JSON.parse(data);
                          if (res && res.secure_url) {
                            form.change('companyImage', res.secure_url);
                          }
                        });
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" values={{ limit: (config.fileSize / (1000 * 1000)) || 20 }} />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <>
                        <div className={css.uploadAvatarWrapper}>
                          <label className={css.label} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                          {/* {error} */}
                          <span className={css.uploadText}>Upload</span>
                        </div>
                        {error}
                      </>
                    );
                  }}
                </Field>
                <p className={css.sectionTitle}>
                  <FormattedMessage id="Step1CompanyForm.companyLogo" />
                </p>
              </div>
            </div>

            <div className={css.bottomWrapper}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id='Step1CompanyForm.step1Company' />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  }
};

Step1CompanyFormComponent.defaultProps = { inProgress: false };

Step1CompanyFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Step1CompanyForm = compose(injectIntl)(Step1CompanyFormComponent);
Step1CompanyForm.displayName = 'Step1CompanyForm';

export default Step1CompanyForm;
