import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldSelect, FieldRadioButton, FieldLocationAutocompleteInput, IconFortehWeb } from '../../../components';

import css from './Step1ProForm.module.css';

const identity = v => v;

const Step1ProFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        pristine,
        displayName,
        configListing,
      } = fieldRenderProps;

      const addressRequiredMessage = intl.formatMessage({
        id: 'Step1ProForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'Step1ProForm.addressNotRecognized',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.signupNext}>
            <h3><FormattedMessage id='Step1ProForm.heading' values={{ displayName }} /></h3>
            <FieldSelect
              id={`${formId}.mainProfession`}
              className={css.inputBox}
              name="mainProfession"
              label={intl.formatMessage({ id: 'Step1ProForm.mainProfession' })}
              validate={validators.required(intl.formatMessage({ id: 'Step1ProForm.mainProfessionRequired' }))}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'Step1ProForm.selectMainProfession' })}
              </option>

              {configListing.companyIndustry
                ? configListing.companyIndustry.map(({ option, label }) => (
                  <option key={option} value={option}>
                    {label}
                  </option>
                ))
                : null}
            </FieldSelect>

            <FieldSelect
              id={`${formId}.availableHoursperWeek`}
              className={css.inputBox}
              name="availableHoursPerWeek"
              label={intl.formatMessage({ id: 'Step1ProForm.availableHoursperWeek' })}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'Step1ProForm.selectavailableHoursperWeek' })}
              </option>

              {configListing.availableWorkingHours.map(({ option, label }) => (
                <option key={option} value={option}>
                  {label}
                </option>
              ))}
            </FieldSelect>

            {values.workType == 'remote'
              ? null
              : <div className={css.setpsLocation}>
                <FieldLocationAutocompleteInput
                  className={css.locationInput}
                  rootClassName={css.input}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="location"
                  label={intl.formatMessage({ id: 'Step1ProForm.address' })}
                  placeholder={intl.formatMessage({
                    id: 'Step1ProForm.addressPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(addressRequiredMessage),
                    validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                <span className={css.locationIcon}>
                  <IconFortehWeb type="location" />
                </span>
              </div>}

            <div className={css.radioButtonRow}>
              <label>{intl.formatMessage({ id: 'Step1ProForm.workType', })}</label>
              <FieldRadioButton
                id="remote"
                name="workType"
                label={intl.formatMessage({ id: 'Step1ProForm.remoteOnly', })}
                value="remote"
                showAsRequired={pristine}
              />
              <FieldRadioButton
                id="hybrid"
                name="workType"
                label={intl.formatMessage({ id: 'Step1ProForm.hybrid' })}
                value="hybrid"
                showAsRequired={pristine}
              />
              <FieldRadioButton
                id="flexible"
                name="workType"
                label={intl.formatMessage({ id: 'Step1ProForm.flexible', })}
                value="flexible"
                showAsRequired={pristine}
              />
              <FieldRadioButton
                id="onsite"
                name="workType"
                label={intl.formatMessage({ id: 'Step1ProForm.onsite' })}
                value="onsite"
                showAsRequired={pristine}
              />
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
             <FormattedMessage id= {(values && values.completeStep1) ? 'Save' :'Step1ProForm.step1Profile' } />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

Step1ProFormComponent.defaultProps = { inProgress: false };

Step1ProFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Step1ProForm = compose(injectIntl)(Step1ProFormComponent);
Step1ProForm.displayName = 'Step1ProForm';

export default Step1ProForm;
