import React, { Component } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { ensureCurrentUser } from '../../../util/data';
import { isUploadImageOverLimitError } from '../../../util/errors';
import {
    Form,
    Avatar,
    IconSpinner,
    FieldSelect,
    PrimaryButton,
    ImageFromFile,
    FieldTextInput,
    FieldLocationAutocompleteInput,
    IconFortehWeb,
} from '../../../components';

import css from './Step2CompanyForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const identity = v => v;

class Step1CompanyFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.submittedValues = {};
    }

    render() {
        return <FinalForm
            {...this.props}
            mutators={{ ...arrayMutators }}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    handleSubmit,
                    inProgress,
                    invalid,
                    intl,
                    values,
                    pristine,
                    displayName,
                } = fieldRenderProps;

                const addressRequiredMessage = intl.formatMessage({
                    id: 'Step2CompanyForm.addressRequired',
                });
                const addressNotRecognizedMessage = intl.formatMessage({
                    id: 'Step2CompanyForm.addressNotRecognized',
                });

                const classes = classNames(rootClassName || css.root, className);
                const submitInProgress = inProgress;
                const submittedOnce = Object.keys(this.submittedValues).length > 0;
                const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
                const submitDisabled = invalid || pristineSinceLastSubmit || inProgress || submitInProgress;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <div className={css.signupNext}>
                            <h3>
                                <FormattedMessage id='Step1CompanyForm.heading' values={{ displayName }} />
                            </h3>

                            <div className={css.setpsLocation}>
                                <FieldLocationAutocompleteInput
                                    className={css.locationInput}
                                    rootClassName={css.input}
                                    inputClassName={css.locationAutocompleteInput}
                                    iconClassName={css.locationAutocompleteInputIcon}
                                    predictionsClassName={css.predictionsRoot}
                                    validClassName={css.validLocation}
                                    name="location"
                                    label={intl.formatMessage({ id: 'Step2CompanyForm.address' })}
                                    placeholder={intl.formatMessage({
                                        id: 'Step2CompanyForm.addressPlaceholder',
                                    })}
                                    useDefaultPredictions={false}
                                    format={identity}
                                    valueFromForm={values.location}
                                    validate={validators.composeValidators(
                                        validators.autocompleteSearchRequired(addressRequiredMessage),
                                        // validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                                    )}
                                />
                                <span className={css.locationIcon}>
                                    <IconFortehWeb type="location" />
                                </span>
                            </div>

                            <FieldTextInput
                                className={css.fiedlInput}
                                type="text"
                                id={'facebookURL'}
                                name="facebookURL"
                                autoComplete="family-name"
                                label={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyFBlabel',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyFBURLPlaceHolder',
                                })}
                                validate={validators.websiteFormatValid('Not a valid URL')}
                            />

                            <FieldTextInput
                                className={css.fiedlInput}
                                type="text"
                                id={'instagramURL'}
                                name="instagramURL"
                                autoComplete="family-name"
                                label={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyInstaLabel',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyInastaPlaceHolder',
                                })}
                                validate={validators.websiteFormatValid('Not a valid URL')}
                            />
                            <FieldTextInput
                                className={css.fiedlInput}
                                type="text"
                                id={'linkedinURL'}
                                name="linkedinURL"
                                autoComplete="family-name"
                                label={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyLinkedinLabel',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyLinkedinPlaceHolder',
                                })}
                                validate={validators.websiteFormatValid('Not a valid URL')}
                            />
                            <FieldTextInput
                                className={css.fiedlInput}
                                type="text"
                                id={'twitterURL'}
                                name="twitterURL"
                                autoComplete="family-name"
                                label={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyTwitterLabel',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'Step2CompanyForm.step2CompanyTwitterPlaceHolder',
                                })}
                                validate={validators.websiteFormatValid('Not a valid URL')}
                            />
                        </div>

                        <div className={css.bottomWrapper}>
                            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                                {(values && values.completeStep2) ? 'Save' : <FormattedMessage id='Step2CompanyForm.step2Company' />}
                            </PrimaryButton>
                        </div>
                    </Form>
                );
            }}
        />
    }
};

Step1CompanyFormComponent.defaultProps = { inProgress: false };

Step1CompanyFormComponent.propTypes = {
    inProgress: bool,

    // from injectIntl
    intl: intlShape.isRequired,
};

const Step2CompanyForm = compose(injectIntl)(Step1CompanyFormComponent);
Step2CompanyForm.displayName = 'Step1CompanyForm';

export default Step2CompanyForm;
