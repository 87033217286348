import React, { useState, useEffect } from 'react';
import { bool, func, object, oneOf, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { camelize } from '../../util/string';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import routeConfiguration from '../../routing/routeConfiguration';

import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';

import {
  Page,
  Heading,
  NamedRedirect,
  LinkTabNavHorizontal,
  SocialLoginButton,
  ResponsiveBackgroundImageContainer,
  Modal,
  LayoutSingleColumn,
  NamedLink,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import ConfirmSignupForm from './ConfirmSignupForm/ConfirmSignupForm';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';
import Step1ProForm from './Step1ProForm/Step1ProForm';
import EmailVerificationInfo from './EmailVerificationInfo';

// We need to get ToS asset and get it rendered for the modal on this page.
import { TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';

// We need to get PrivacyPolicy asset and get it rendered for the modal on this page.
import { PrivacyPolicyContent } from '../../containers/PrivacyPolicyPage/PrivacyPolicyPage';

import { updateProfile, uploadImage } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { TOS_ASSET_NAME, PRIVACY_POLICY_ASSET_NAME } from './AuthenticationPage.duck';

import { FacebookLogo, GoogleLogo, LinkedinLogo } from './socialLoginLogos';
import Step1CompanyForm from './Step1CompanyForm/Step1CompanyForm';
import Step2CompanyForm from './Step2CompanyForm/Step2CompanyForm';

import css from './AuthenticationPage.module.css';
import { createSlug } from '../../util/urlHelpers';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

// Social login buttons are needed by AuthenticationForms
export const SocialLoginButtonsMaybe = props => {
  const routeConfiguration = useRouteConfiguration();
  const { isLogin, showFacebookLogin, showLinkedinLogin, showGoogleLogin, from } = props;
  const showSocialLogins = showFacebookLogin || showGoogleLogin || showLinkedinLogin;

  const getDefaultRoutes = () => {
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routeConfiguration);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routeConfiguration);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithFacebook = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithGoogle = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  const authWithLinkedin = () => {
    const defaultRoutes = getDefaultRoutes();
    const {
      baseUrl,
      fromParam,
      defaultReturnParam,
      defaultConfirmParam,
    } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/linkedin?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  return showSocialLogins ? (
    <div className={css.idpButtons}>
      {/* <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div> */}

      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithFacebook()}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}
      {/* {showLinkedinLogin && authWithLinkedin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={authWithLinkedin} className={css.linkedinBtn} disabled={true}>
            <span className={css.buttonIcon}>{LinkedinLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithLinkedin" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithLinkedin" />
            )}
          </SocialLoginButton>
        </div>
      ) : null} */}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={() => authWithGoogle()}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}
    </div>
  ) : null;
};

// Tabs for SignupForm and LoginForm
export const AuthenticationForms = props => {

  const {
    isLogin,
    isStep1,
    isStep2,
    showFacebookLogin,
    showGoogleLogin,
    showLinkedinLogin,
    from,
    configListing,
    currentUser,
    displayName,
    isCompanyType,
    submitLogin,
    loginError,
    signupError,
    authInProgress,
    submitSignup,
    image,
    history,
    termsAndConditions,
    updateInProgress,
    onUpdateProfile,
    onImageUpload,
    uploadInProgress,
    uploadImageError,
    submitSignupWithIdp,
    onOpenTermsOfService,
    onOpenPrivacyPolicy,
    currentUserListings,
    params
  } = props;
  const fromState = { state: from ? { from } : null };
  const tabs = [
    {
      text: (
        <Heading as={!isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </Heading>
      ),
      selected: !isLogin,
      linkProps: {
        name: 'SignupPage',
        to: fromState,
      },
    },
    {
      text: (
        <Heading as={isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </Heading>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const handleSubmitSignup = values => {
    const { fname, lname, ...rest } = values;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    submitSignup(params).then(() => {
      history.push(createResourceLocatorString('Step1Page', routeConfiguration(), {}, {}));
    });
  };

  const handleSubmit = (values, type) => {
    const { history } = props;
    const { profileImage, ...rest } = values;

    const uploadedImage = props.image;

    let {
      completeStep1 = true,
      completeStep2 = type == 'STEP-2' ? true : false
    } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};

    if (type == 'STEP-2') {
      completeStep2 = true;
      const companyData = {
        distinct_id: currentUser.id.uuid,
        user_id: currentUser.id.uuid,
        companyName: values.companyName,
        companySize: values.sizeOfCompany,
        companyIndustry: values.companyIndustry,
        companyFoundingYear: values.foundingYear,
      };
      if (values.location && values.location.selectedPlace) {
        const location = values?.location?.selectedPlace?.address.trim().split(', ');
        Object.assign(companyData, {
          companyCity: location[0],
          companyCountry: location[location.length - 1],
          companyProvinceAndState: location && location.length > 2 ? location[1] : location[0],
        });
      }
      if (currentUser && currentUser.id && (currentUser.attributes.profile.protectedData.currentSeeds || currentUser.attributes.profile.protectedData.CurrentTokens)) {
        const CurrentSeeds = currentUser.attributes.profile.protectedData.currentSeeds || currentUser.attributes.profile.protectedData.CurrentTokens;
        Object.assign(companyData, {
          currentSeeds: CurrentSeeds,
          environment: (process.env && process.env.REACT_APP_ENV) || 'development',
        })
      }
      mixpanel.track('User-Company', companyData);
    } else {
      if (!isCompanyType) {
        const proData = {
          distinct_id: currentUser.id.uuid,
          user_id: currentUser.id.uuid,
          locationStatus: values.workType,
          hourlyRate: values.availableHoursPerWeek,
        }
        if (values.location && values.location.selectedPlace) {
          const location = values?.location?.selectedPlace?.address.trim().split(', ');
          Object.assign(proData, {
            proCity: location[0],
            proCountry: location[location.length - 1],
            proProvinceAndState: location && location.length > 2 ? location[1] : location[0],
            environment: (process.env && process.env.REACT_APP_ENV) || 'development',
          });
        }
        mixpanel.track('User-Pro', proData);
      }
    }

    // Update profileImage only if file system has been accessed
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? {
          publicData: { ...rest, completeStep1, completeStep2, },
          profileImageId: uploadedImage.imageId
        }
        : { publicData: { ...rest, completeStep1, completeStep2, } };

    onUpdateProfile(updatedValues).then(() => {


      // typeof window != 'undefined' && window.localStorage.removeItem('signupValues');
      type == 'STEP-2' || !isCompanyType
        ? history.push(createResourceLocatorString((type == 'STEP-2') ? 'LandingPage' : (currentUserListings && currentUserListings.length) ? 'EditListingPage' : 'NewListingPage', routeConfiguration(), (currentUserListings && currentUserListings.length) ? {
          id: currentUserListings[0]?.id?.uuid,
          slug: createSlug(currentUserListings[0]?.attributes?.title), type: 'edit', tab: 'details'
        } : {}, {}))
        : history.push(createResourceLocatorString('Step2Page', routeConfiguration(), {}, {}));
    });
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const signupValues = (typeof window != 'undefined' && JSON.parse(window.localStorage.getItem('signupValues'))) || {};
  const initialValues = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
  const profileImageId = (currentUser && currentUser.id && currentUser.profileImage)
    ? currentUser.profileImage.id
    : null;
  const profileImage = image || { imageId: profileImageId };
  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  return (
    <div className={css.content}>
      {(isStep1 || isStep2) ? null : <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />}
      {(isStep1 || isStep2) ? null : loginOrSignupError}

      {isStep1
        ? isCompanyType
          ? <Step1CompanyForm
            currentUser={currentUser}
            displayName={displayName}
            inProgress={updateInProgress}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            configListing={configListing}
            profileImage={profileImage}
            onImageUpload={onImageUpload}
            uploadInProgress={uploadInProgress}
            uploadImageError={uploadImageError}
          />
          : <Step1ProForm
            initialValues={{
              workType: 'remoteOnly',
              ...initialValues
            }}
            configListing={configListing}
            displayName={displayName}
            currentUserListings={currentUserListings}
            inProgress={updateInProgress}
            onSubmit={handleSubmit}
          />
        : isStep2 ?
          <Step2CompanyForm
            currentUser={currentUser}
            displayName={displayName}
            inProgress={updateInProgress}
            onSubmit={(e) => handleSubmit(e, 'STEP-2')}
            initialValues={initialValues}
            configListing={configListing}
            profileImage={profileImage}
            onImageUpload={onImageUpload}
            uploadInProgress={uploadInProgress}
            uploadImageError={uploadImageError}
          />
          : isLogin ? (
            <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
          ) : (
            <SignupForm
              className={css.signupForm}
              onSubmit={handleSubmitSignup}
              inProgress={authInProgress}
              configListing={configListing}
              initialValues={{ accountType: (params && params.type == 'pro') ? "fractionalPro" : "company", ...signupValues }}
              termsAndConditions={termsAndConditions}
              onOpenTermsOfService={onOpenTermsOfService}
              onOpenPrivacyPolicy={onOpenPrivacyPolicy}
              params={params}
            />
          )}

      <SocialLoginButtonsMaybe
        isLogin={isLogin}
        showLinkedinLogin={showLinkedinLogin}
        showFacebookLogin={showFacebookLogin}
        showGoogleLogin={showGoogleLogin}
        from={from}
      />
    </div>
  );
};

// Form for confirming information from IdP (e.g. Facebook)
// This is shown before new user is created to Marketplace API
const ConfirmIdProviderInfoForm = props => {
  const { authInfo, authInProgress, confirmError, currentUser, onUpdateProfile, submitSignupWithIdp, termsAndConditions } = props;
  const idp = authInfo ? authInfo.idpId.replace(/^./, str => str.toUpperCase()) : null;

  const handleSubmitConfirm = values => {
    const { idpToken, email, firstName, lastName, idpId } = authInfo;
    const { email: newEmail, firstName: newFirstName, lastName: newLastName, ...rest } = values;

    // Pass email, fistName or lastName to Marketplace API only if user has edited them
    // sand they can't be fetched directly from idp provider (e.g. Facebook)

    const authParams = {
      ...(newEmail !== email && { email: newEmail }),
      ...(newFirstName !== firstName && { firstName: newFirstName }),
      ...(newLastName !== lastName && { lastName: newLastName }),
    };

    // If the confirm form has any additional values, pass them forward as user's protected data
    const protectedData = !isEmpty(rest) ? { ...rest } : null;

    currentUser && currentUser.id
      ? onUpdateProfile({
        protectedData
      })
        .then(() => history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {})))
      : submitSignupWithIdp({
        idpToken,
        idpId,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      });
  };

  const confirmErrorMessage = confirmError ? (
    <div className={css.error}>
      {isSignupEmailTakenError(confirmError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  ) : null;

  return (
    <div className={css.content}>
      <Heading as="h1" rootClassName={css.signupWithIdpTitle}>
        <FormattedMessage id="AuthenticationPage.confirmSignupWithIdpTitle" values={{ idp }} />
      </Heading>

      <p className={css.confirmInfoText}>
        <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
      </p>
      {confirmErrorMessage}
      <ConfirmSignupForm
        className={css.form}
        onSubmit={handleSubmitConfirm}
        inProgress={authInProgress}
        termsAndConditions={termsAndConditions}
        authInfo={authInfo}
        idp={idp}
      />
    </div>
  );
};

export const AuthenticationOrConfirmInfoForm = props => {
  const {
    tab,
    authInfo,
    from,
    currentUser,
    displayName,
    isCompanyType,
    showFacebookLogin,
    showGoogleLogin,
    showLinkedinLogin,
    submitLogin,
    submitSignup,
    submitSignupWithIdp,
    authInProgress,
    loginError,
    signupError,
    confirmError,
    termsAndConditions,
    image,
    history,
    configListing,
    updateInProgress,
    onUpdateProfile,
    uploadInProgress,
    uploadImageError,
    updateProfileError,
    onImageUpload,
    onOpenTermsOfService,
    onOpenPrivacyPolicy,
    currentUserListings,
    params
  } = props;
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';
  const isStep1 = tab === 'step1';
  const isStep2 = tab === 'step2';

  return isConfirm ? (
    <ConfirmIdProviderInfoForm
      authInfo={authInfo}
      submitSignupWithIdp={submitSignupWithIdp}
      authInProgress={authInProgress}
      confirmError={confirmError}
      currentUser={currentUser}
      onUpdateProfile={onUpdateProfile}
      termsAndConditions={termsAndConditions}
      onOpenTermsOfService={onOpenTermsOfService}
    />
  ) : (
    <AuthenticationForms
      isLogin={isLogin}
      isStep1={isStep1}
      isStep2={isStep2}
      showLinkedinLogin={showLinkedinLogin}
      showFacebookLogin={showFacebookLogin}
      showGoogleLogin={showGoogleLogin}
      from={from}
      history={history}
      currentUser={currentUser}
      displayName={displayName}
      isCompanyType={isCompanyType} // @shubham you can change with true value for company
      loginError={loginError}
      signupError={signupError}
      submitLogin={submitLogin}
      authInProgress={authInProgress}
      submitSignup={submitSignup}
      image={image}
      configListing={configListing}
      updateInProgress={updateInProgress}
      onUpdateProfile={onUpdateProfile}
      uploadInProgress={uploadInProgress}
      uploadImageError={uploadImageError}
      updateProfileError={updateProfileError}
      onImageUpload={onImageUpload}
      termsAndConditions={termsAndConditions}
      onOpenTermsOfService={onOpenTermsOfService}
      onOpenPrivacyPolicy={onOpenPrivacyPolicy}
      currentUserListings={currentUserListings}
      params={params}
    />
  );
};

const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;
};

export const AuthenticationPageComponent = props => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());
  const [authError, setAuthError] = useState(getAuthErrorFromCookies());
  const config = useConfiguration();

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }
  }, []);

  // On mobile, it's better to scroll to top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const {
    authInProgress,
    currentUser,
    intl,
    history,
    isAuthenticated,
    location,
    loginError,
    scrollingDisabled,
    signupError,
    submitLogin,
    submitSignup,
    confirmError,
    submitSignupWithIdp,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    image,
    onUpdateProfile,
    updateInProgress,
    uploadInProgress,
    uploadImageError,
    updateProfileError,
    onImageUpload,
    currentUserListings,
    params
  } = props;
  // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const isLogin = tab === 'login';

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const { accountType, completeStep1 = false, completeStep2 = false, } = (currentUserLoaded && user.attributes.profile.publicData) || {};
  const isCompanyType = accountType ? accountType == 'company' : true;
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
  const displayName = (currentUserLoaded && (user.attributes.profile.displayName)) || ''; //isCompanyType ? user.attributes.profile.publicData.companyName : 

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && currentUserLoaded && showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  } else if (isAuthenticated && currentUserLoaded && !completeStep1 && tab != 'step1') {
    return <NamedRedirect name="Step1Page" />;
  } else if (isAuthenticated && currentUserLoaded && isCompanyType && completeStep1 && !completeStep2 && tab != 'step2') {
    return <NamedRedirect name="Step2Page" />;
  } else if (isAuthenticated && from) {
    return <Redirect to={from} />;
  } else if (isAuthenticated && currentUserLoaded && !showEmailVerification && !['step1', 'step2'].includes(tab)) {
    return <NamedRedirect name="LandingPage" />;
  }

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <p className={css.error}>
      <FormattedMessage id={resendErrorTranslationId} />
    </p>
  ) : null;

  const marketplaceName = config.marketplaceName;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { marketplaceName });
  const schemaDescription = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionLogin' }, { marketplaceName })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaDescriptionSignup' }, { marketplaceName });

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer className={topbarClasses} />}
        footer={<FooterContainer />}
      >
        <ResponsiveBackgroundImageContainer
          className={css.root}
          childrenWrapperClassName={css.contentContainer}
          as="section"
          image={config.branding.brandImage}
          sizes="100%"
          useOverlay
        >
          {showEmailVerification ? (
            <EmailVerificationInfo
              name={user.attributes.profile.firstName}
              email={<span className={css.email}>{user.attributes.email}</span>}
              onResendVerificationEmail={onResendVerificationEmail}
              resendErrorMessage={resendErrorMessage}
              sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            />
          ) : (
            <AuthenticationOrConfirmInfoForm
              tab={tab}
              authInfo={authInfo}
              from={from}
              history={history}
              currentUser={currentUser}
              displayName={displayName}
              isCompanyType={isCompanyType}
              showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
              showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
              showLinkedinLogin={!!process.env.REACT_APP_LINKEDIN_CLIENT_ID}
              submitLogin={submitLogin}
              submitSignup={submitSignup}
              submitSignupWithIdp={submitSignupWithIdp}
              authInProgress={authInProgress}
              currentUserListings={currentUserListings}
              loginError={loginError}
              signupError={signupError}
              confirmError={confirmError}
              image={image}
              configListing={config.listing}
              onUpdateProfile={onUpdateProfile}
              updateInProgress={updateInProgress}
              uploadInProgress={uploadInProgress}
              uploadImageError={uploadImageError}
              updateProfileError={updateProfileError}
              onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
              onOpenTermsOfService={() => setTosModalOpen(true)}
              onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => setTosModalOpen(true)}
                  onOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
                  intl={intl}
                />
              }
              params={params}
            />
          )}
        </ResponsiveBackgroundImageContainer>
      </LayoutSingleColumn>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <TermsOfServiceContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacyPolicy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.privacyWrapper}>
          <PrivacyPolicyContent
            inProgress={tosFetchInProgress}
            error={tosFetchError}
            data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
          />
        </div>
      </Modal>
    </Page>
  );
};

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  confirmError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
  showSocialLoginsForTests: false,
  privacyAssetsData: null,
  privacyFetchInProgress: false,
  privacyFetchError: null,
  tosAssetsData: null,
  tosFetchInProgress: false,
  tosFetchError: null,
};

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,

  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup', 'confirm', 'step1', 'step2']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // to fetch privacy-policy page asset
  // which is shown in modal
  privacyAssetsData: object,
  privacyFetchInProgress: bool,
  privacyFetchError: propTypes.error,

  // to fetch terms-of-service page asset
  // which is shown in modal
  tosAssetsData: object,
  tosFetchInProgress: bool,
  tosFetchError: propTypes.error,

  // from withRouter
  location: shape({ state: object }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    image,
    updateInProgress,
    uploadInProgress,
    uploadImageError,
    updateProfileError,
  } = state.ProfileSettingsPage;
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError, currentUserListings } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    currentUserListings,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    image,
    updateInProgress,
    uploadInProgress,
    uploadImageError,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: payload => dispatch(updateProfile(payload)),
  onImageUpload: payload => dispatch(uploadImage(payload)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
